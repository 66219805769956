.adminPageProfileCreation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.adminPageProfileCreation input {
    width: 14rem;
    height: 2.5rem;
    border: 2px solid var(--dashboard-card-border);
    border-radius: 5px;
    outline: none;
    padding: 5px;
}

#selectRole {
    width: 14rem;
    height: 2.5rem;
    border: 2px solid var(--dashboard-card-border);
    /* border-left: none; */
    border-radius: 5px;
    outline: none;
    padding: 5px;
    cursor: pointer;
    background-color: transparent;
}

.adminProfileCreationFormBtns button {
    width: fit-content;
    height: fit-content;
    padding: 6px 25px;
    border-radius: 20px;
    outline: none;
    cursor: pointer;
}

.adminProfileCreationFormBtns button:first-child {
    background: var(--btn-color);
    color: white;
    /* border: 2px solid var(--dashboard-card-border); */
    border: none;
}

.adminProfileCreationFormBtns button:last-child {
    color: black;
    background: transparent;
    border: 2px solid var(--dashboard-card-border);
    /* border: none; */
}

.errorMsgContainer{
    height: 25px;
}

.errorMsgContainer p{
    color: #e22424;
}