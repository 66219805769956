* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #2c3857;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Poppins:wght@200&display=swap");

.ParentRapper {
  height: 100vh;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: #f6fafd;
}

:root {
  --nav-btn-back-color: #7471f5;
  --nav-inactive-back-color: #f6fafd;
  --inactive-svg-fill: #747c90;
  --border-color: #e2e4e8;
  --page-heading-color: #0f1d40;
  --logo-color: #ff2c52;
  --dashboard-card-back-color: #eceff4;
  --dashboard-card-border: rgba(116, 124, 144, 0.5);
  --dashboard-card-inner-border: rgba(0, 0, 0, 0.1);
  --dashboard-card-color: #252539;
  --heading-color: #003482;
  --placeholder-color: #747c90;
  --btn-color: #514ef3;
  --forms-label-color: #49536e;
  --alart-background: #f1f1f1;
  --new-font-color: #8c93a3;
  --svg-stroke-color: #747c90;
}

.p-5 {
  padding: 5px;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.p-3 {
  padding: 3px;
}

.p-2 {
  padding: 2px;
}

.NavCompiler {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

/* global class */
.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}

.flex-col {
  display: flex !important;
  flex-direction: column !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.allergies {
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.allergiesCheckBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.allergies .allergiesCheckBtn input::after {
  content: attr(value);
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.allergies .allergiesCheckBtn input {
  appearance: none;
  width: 10rem;
  cursor: pointer;
  height: 2.3rem;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--btn-color);
  overflow: hidden;
  position: relative;
}

.allergies .allergiesCheckBtn input:checked::after {
  background-color: var(--btn-color);
  color: white;
}

.gridEqual {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  width: 100%;
}

.DataCols {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 1rem 1rem 0 1rem;
  gap: 1rem;
}

.alertBar {
  color: var(--alertBarForntColor);
  font-size: 19px;
  width: fit-content;
  font-weight: bold;
  height: fit-content;
  padding: 10px 30px;
  position: absolute;
  height: 3rem;
  border-radius: 22px;
  animation: AlertAnim;
  animation-duration: 2s;
  opacity: 1;
  transition: 2s;
  bottom: 20px;
  left: 48%;
}

@keyframes AlertAnim {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body {
  overflow-x: hidden;
}

.pagePath {
  font-size: 11px;
  line-break: normal;
  align-items: center;
  display: flex;
}

.pagePath img {
  height: 20px;
  padding-right: 10px;
  cursor: pointer;
}

.inLineChangeInputTag {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  padding: 5px 10px;
  border: 2px solid #e4e6e8;
  outline: none;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.g-05 {
  gap: 3px;
}

.g-1 {
  gap: 1rem;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.scrollAbleDiv {
  overflow-y: scroll;
}

.scrollAbleDiv::-webkit-scrollbar {
  display: none;
}

.toggleInputBtnDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 2.5rem;
  border-radius: 22px;
  border: 1px solid var(--dashboard-card-border);
  position: relative;
  padding: 5px;
  gap: 5px;
  overflow: hidden;
}

.toggleInputBtnDiv input[type="checkbox"] {
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  cursor: pointer;
}

.toggleInputBtnDiv input[type="checkbox"]::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  padding-left: 12px;
}

.toggleInputBtnDiv .tagsRemover {
  right: 5px;
  cursor: pointer;
  z-index: 5;

  fill: gray;
  background-color: gray;
  border-radius: 50%;
  overflow: hidden;
}

.toggleInputBtnDiv input[type="checkbox"]:checked:after {
  content: attr(value);
  background-color: var(--btn-color);
  color: white;
}

/* .toggleInputBtnDiv input[type="checkbox"]:checked .toggleInputBtnDiv .tagsRemover {
  fill: red;
} */

button:disabled {
  cursor: not-allowed;
}

button:disabled * {
  cursor: not-allowed;
}

.g-2 {
  gap: 2rem;
}

.flex-1 {
  flex: 1;
}

.emrProfileCards {
  display: flex;
  flex-direction: column;
  /* height: 65vh; */
  margin-bottom: 23px;
}

.addBtn {
  text-decoration: none;
  width: fit-content;
  height: 2.5rem;
  padding: 0 15px;
  background-color: var(--btn-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 22px;
  color: white;
  font-size: 13px;
}

.addBtn img {
  width: 1.3rem;
}

.addBtn span {
  color: white;
}

.justify-end {
  justify-content: end;
}

.align-center {
  align-items: center;
}

.back-success {
  background-color: #70cf97;
}

.-white {
  color: white;
}

.size14 {
  font-size: 14px;
}

.size10 {
  font-size: 10px;
}

.g-3 {
  gap: 3rem;
}

.color-border {
  color: rgba(116, 124, 144, 0.548);
}

.basic-gray {
  color: var(--dashboard-card-border);
}

.overflow-hide {
  overflow: hidden;
}

.pb-1 {
  padding-bottom: 1rem;
}

.relative {
  position: relative;
}

.editBillingBtn {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.blankAlertCom {
  flex: 1;
  background-color: rgb(116 124 144 / 9%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: var(--dashboard-card-border);
  font-weight: 700;
}

.blankAlertCom svg {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  animation: Loader 1s infinite linear;
}

@keyframes Loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.space-between {
  justify-content: space-between;
}

.addRowBtn {
  width: 5rem;
  height: 2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: var(--btn-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* pa */
}

.removeBtn {
  width: 5rem;
  height: 2rem;
  border-radius: 5px;
  border: none;
  outline: none;
  /* background-color: var(--btn-color); */
  background-color: transparent !important;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.absolute {
  position: absolute;
}

.ReactModal__Content,
.ReactModal__Content--after-open {
  z-index: 99999999 !important;
}

.inputs {
  width: 100%;
  height: 3rem;
  padding: 10px;
  border: 2px solid var(--dashboard-card-border);
  outline: none;
  border-radius: 13px;
}

.nowrap {
  text-wrap: nowrap;
}

button:disabled {
  cursor: not-allowed !important;
  background-color: var(--dashboard-card-border) !important;
  border: none !important;
}

.flex-end {
  align-items: flex-end;
}

.w-50 {
  width: 50%;
}

.bold {
  font-weight: bold;
}

.list {
  list-style-type: decimal;
}

.color-gray {
  color: var(--forms-label-color);
}

.w-6r {
  width: 6rem;
}

.viewallEMRBtn {
  background-color: transparent;
  border: none;
  outline: none;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  color: var(--btn-color);
}

.circuler {
  border-radius: 50%;
}

.w-5r {
  width: 3.5rem;
}

.h-5r {
  height: 3.5rem;
}

.absolute {
  position: absolute;
}

.w-100vh {
  width: 100vw;
  height: 100vh;
}

.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.fit-width {
  width: fit-content;
}

.fit-height {
  height: fit-content;
}

.w-50 {
  width: 50%;
}

.sectest {
  border: 2px solid red;
}

.w-30r {
  width: 30rem;
}

.bottom-border-dark {
  border-bottom: 2px solid var(--dashboard-card-color);
}

.blue-back {
  background-color: var(--btn-color);
}

.c-white {
  color: white;
}

.basic-button {
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  padding: 8px 10px;
  border: none;
}

.green-back {
  background-color: #00CC6F;
}

.hidden {
  overflow: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll {
  overflow: scroll;
}

.scroll::-webkit-scrollbar {
  display: none;
}


/*
==========================================
      Over Write The Modal Class
===========================================
*/

.mantine-1w30zfw {
  align-items: center;
}

.mantine-grld6l {
  max-width: 350px;
  border-radius: 15px;
}