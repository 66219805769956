.aviailibilitySection {
    width: 100%;
    flex: 1;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    padding-top: 2rem;
}

.availibilityCalender {
    width: fit-content;
    height: fit-content;
    padding: 2.5rem 10px;
    border: 2px solid var(--dashboard-card-border);
    border-radius: 15px;
}

.availableTimrSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.availibilityTime {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.availibilityTime .fromTime,
.toTime {
    width: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
}

.availibilityTime .toTime input[type=radio],
.availibilityTime .fromTime input[type=radio] {
    appearance: none;
    width: 100%;
    height: 2.3rem;
    border-radius: 10px;
    position: relative;
    border: 1px solid #9795F8;
    cursor: pointer;
    overflow: hidden;
}

.availibilityTime .toTime input[type=radio]::after,
.availibilityTime .fromTime input[type=radio]::after {
    content: attr(value);
    width: 100%;
    height: 100%;
    color: black;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.availibilityTime .toTime input[type=radio]:checked::after,
.availibilityTime .fromTime input[type=radio]:checked::after {
    background-color: #9795F8;
    color: white;
}

.markAvailableBtn button {
    padding: 9px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #1A740E;
    color: white;
    cursor: pointer;
}