
.hospitalCreateForm{
    width: 22rem;
    height: 24rem;
    /* border: 2px solid red; */
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: ; */
    gap: 1.5rem;
}
.hospitalCreateInput{
    padding: 8px 10px;
    font-size: 15px;
    outline: none;
    border: 2px solid var(--dashboard-card-border);
    border-radius: 5px;
}
.hospitalCreateForm label{
    font-weight: bold;
}

.hospitalidGenaratorBtn{
    padding: 5px 5px;
    background-color: black;

    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.hospitalCreateBtn{
    background-color: black;
    color: white;
    outline: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    width: 7rem;
    height: 2.5rem;
}