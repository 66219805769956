.pageTitle p {
    font-size: 11px;
}

.singlePatientEMRSection {
    flex: 1;
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
}

.singlePatientEMRSection {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: space-between;
}

.profileEMRMedicalHistorySection {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.shortEmrProfile {
    border: 2px solid var(--new-font-color);
    border-radius: 15px;
    width: 100%;
    height: 40%;
}

.shortEmrMedicalHistory {
    border: 2px solid var(--new-font-color);
    width: 100%;
    flex: 1;
    padding: 10px;
    border-radius: 15px;
}

.profileAppointmentDiagnosticEMR {
    width: 100%;
}

.shortEmrProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.shortEmrProfile img {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 50%;
}

.shortEmrProfile .patientUHID {
    color: var(--new-font-color);
}

.viewProfileEmRPageBtn {
    text-decoration: none;
    padding: 10px 10px;
    background-color: var(--btn-color);
    color: white;
    border: none;
    outline: none;
    border-radius: 15px;
}

.profileAppointmentDiagnosticEMR {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.profileAppointmentDiagnosticEMR .singleprofileAppointmentEMR {
    border: 2px solid var(--new-font-color);
    height: 50%;
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.profileAppointmentDiagnosticEMR .singleprofileDiagnosticEMR {
    border: 2px solid var(--new-font-color);
    height: 50%;
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 6px;
    justify-content: center;
}

.singleEMRFilterHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.singleEMRFilterHeader .singleEMRFIlter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.singleEMRFilterHeader h3,
.singleEMRFilterHeader .singleEMRFIlter {
    width: fit-content;
    height: 3rem;
    height: fit-content;
}

.singleEMRFIlter .flexRow {
    gap: 5px;
    align-items: center;
}

.singleEMRFIlter .flexRow input {
    border: 1px solid var(--new-font-color);
    /* border: none; */
    border-radius: 11px;
    outline: none;
    padding: 2px 10px;
}

.singleEMRFIlter .flexRow input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
}

.singlePatientEMRList {
    /* flex: 1; */
    height: 11rem;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.singlePatientEMRList::-webkit-scrollbar {
    display: none;
}

.singleEMRViewAllBtnSection {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 1.5rem;
    width: 100%;
}

.singleEMRViewAllBtnSection button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #747C90;
}

/* emr list */
.singlePatientEMRList ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.singlePatientEMRList ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: rgb(200, 200, 200); */
    padding: 5px 0;
    font-size: 15px;
    font-weight: 700;
}

.singleDiagnosticEMR .diagnosticTitle {
    font-size: 15px;
}

.singlePatientEMRList ul li .EMRdocName,
.singlePatientEMRList ul li .DateTime,
.singlePatientEMRList ul li .patientVisitedStatus,
.singlePatientEMRList ul li .printBtnsection,
.singlePatientEMRList ul li .singleDiagnosticEMR {
    width: calc(100%/4);
}

.singlePatientEMRList ul li .DateTime {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
    justify-content: center;
}

.singlePatientEMRList ul li .DateTime span:first-child {
    font-size: 13px;
}

.singlePatientEMRList ul li .DateTime span:last-child {
    font-weight: normal !important;
    color: #747C90;
    font-size: 12px;
}

.singlePatientEMRList ul li .viewPatientVisitedStatus span {

    color: var(--EMRPagePatientAppointmentColor) !important;
}

.singlePatientEMRList ul li .viewPatientVisitedStatus {
    padding: 5px;
    background: var(--EMRPagePatientAppointmentBColor) !important;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
    font-weight: normal !important;
}

.singlePatientEMRList ul li .patientVisitedStatus,
.singlePatientEMRList ul li .printBtnsection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.printBtnsection button {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    border: none;
    outline: none;
    background: transparent;
    font-weight: 700;
}
.printBtnsection a {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    border: none;
    outline: none;
    background: transparent;
    font-weight: 700;
}


/* diagnostic EMR list */

.singleDiagnosticEMRCommonIcon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(219, 219, 219); */
    /* box-shadow: inset 0 0 20px -12px rgba(147, 144, 144, 0.20); */
    box-shadow: inset 0 0 39px -33px;
}

.singleDiagnosticEMRCommonIcon img {
    width: fit-content;
    height: fit-content;
}

.singleDiagnosticEMRTitleAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.singleDiagnosticEMR {
    display: flex;
    flex-direction: row;
    align-items: center;
}