.doctorProfilePatientForm {
    height: 100%;
    /* border: 2px solid red; */
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;

}

.doctorProfileAppointmentForm {
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    border-radius: 15px;
    padding: 1rem;
    border: 2px solid var(--dashboard-card-border);

}

.appointmentFormChangeBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
}

.appointmentFormChangeBtns button {
    width: 10rem;
    color: white;
    /* margin-bottom: 7px; */
    height: 80%;
    /* margin-top: 7px; */
    cursor: pointer;
    padding: 8px 0.5rem;
    border-radius: 7px;
    background-color: var(--btn-color);
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.appointmentFormChangeBtns button span {
    color: white;
}

.appointmentFormChangeBtns button:disabled {
    background-color: var(--dashboard-card-border);
    cursor: not-allowed;
}