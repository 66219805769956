.allAppointmentTableActionBtn{
    width: fit-content !important;
    height: fit-content !important;
    text-decoration: none !important;
    background-color: var(--btn-color) !important;
    padding: 3px 10px !important;
    border-radius: 22px !important;
    color: white;
    font-weight: normal !important;
}
.patientListStatus{
    position: relative;
}
.patientListStatus::after{
    content: attr(value);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    color: var(--status-color);
    background-color: var(--status-backcolor);
    z-index: 0;
    padding: 3px 0;
}