.docProfileMainSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}
.PatientListAndDateFilter{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
}
.doctorProfileTopSubLinks {
    width: fit-content;
    height: 8%;
    border-bottom: 2px solid var(--dashboard-card-border);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3.5rem;
    padding-bottom: 5px;
}

.docProfileNavLinks {
    text-decoration: none;
    width: fit-content;
    height: fit-content;
    position: relative;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: transparent !important;
}

.docProfileNavLinks .docprofileNavManuIcons {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}



.doctorProfileTopInActiveNavMenu .docprofileNavManuIcons {
    background-color: white;
    box-shadow: 0 0 13px -5px black;
}

.doctorProfileTopActiveNavMenu::after {
    content: "";
    position: absolute;
    bottom: -9px;
    background-color: var(--btn-color);
    height: 4px;
    width: 112%;
    left: 0px;
    border-radius: 22px;
}

.doctorProfileTopActiveNavMenu span {
    color: var(--btn-color) !important;

}

.doctorProfileTopInActiveNavMenu span {
    color: black;


}

.doctorProfileTopInActiveNavMenu:first-child svg {
    /* stroke: ; */
    fill: var(--inactive-svg-fill);
    stroke: none;
}


.doctorProfileTopActiveNavMenu:first-child svg path {
    stroke: none;
}

.doctorProfileTopActiveNavMenu:first-child svg {
    stroke: none !important;
    fill: white;
}

.doctorProfileTopActiveNavMenu svg path {
    stroke: var(--btn-color);
}

.doctorProfileTopInActiveNavMenu svg {
    /* fill: var(--inactive-svg-fill); */
    width: 1.5rem;
    stroke: var(--svg-stroke-color);
    height: 1.5rem;
}

.doctorProfileTopActiveNavMenu .docprofileNavManuIcons {
    background-color: var(--btn-color);
    padding: 5px;
}

.doctorProfileTopActiveNavMenu svg {
    fill: white;
    stroke: white;
}

.doctorCalenderScheduleViewer {
    width: 100%;
    flex: 1;
    /* overflow-y: scroll; */
}

.doctorCalenderScheduleViewer::-webkit-scrollbar {
    /* display: none; */
}

.doctorProfileTopActiveNavMenu:last-child svg path {
    fill: white;
    stroke: white;
}

.doctorProfileCalenderMonthViewerMonthBtnAndMonthName {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;

}

.doctorProfileCalenderMonthViewerMonthBtnAndMonthName button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 23px;
}

.doctorProfileCalenderMonthViewerMonthBtnAndMonthName button img {
    height: 1rem;
    width: 1rem;
}

.doctorProfileCalenderMonthViewer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: #F2F2F2;
    border-radius: 15px;
    width: 11rem;
    height: 4.5rem;
}

.doctorProfileCalenderMonthViewer .title {
    font: 15px;
    color: var(--inactive-svg-fill);
}

/* days filter */
.filterCalenderDoctorProfile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;
}

.doctorProfileDaysFilter {
    width: fit-content;
    height: 4.5rem;
    /* display: flex; */
    align-items: center;
    gap: 2rem;
    border-radius: 15px;
    padding: 0 20px;
    background-color: #F2F2F2;
    font-size: 15px;
    font-weight: 700;
}

.doctorProfileDaysFilter button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.daysCount {
    width: 3.5rem;
    height: 3.5rem;
    gap: 6px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
    background-color: white;
    border-radius: 50%;
}

.daysCount span:first-child {
    color: var(--inactive-svg-fill);
}

.docProfilePatientListFilterByDate{
    width: fit-content;
    height: fit-content;
    padding: 2rem 5px;
    border: 1px solid var(--dashboard-card-border);
    border-radius: 15px;
}

.blankAppointment{
    height: 100%;
    color: rgb(128 128 128 / 50%);
    position: absolute;
    font-weight: 700;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}