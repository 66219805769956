.mantine-1lvf6d9 {
    border-radius: 15px !important;
}

.InputFieldContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    padding: 0.3rem 0;
}

.InputFieldContainer label {
    font-family: "Nunito", sans-serif;
    font-weight: 600;
    padding: 0.3rem 0.1rem;
}

.errorMsg {
    width: 20rem;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.errorMsg p {
    color: #d41111;
    font-family: "Nunito", sans-serif;
    font-weight: 600;
}

.InputFieldContainer input {
    background-color: rgb(40, 52, 62, 0.07);
    border: none;
    border-radius: 8px;
    font-weight: 700;
    outline: none;
    padding: 0.8rem;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.btnContainer button {
    background-color: var(--btn-color);
    color: white;
    cursor: pointer;
    padding: 12px 2rem;
    border-radius: 21px;
    outline: none;
    border: 0;
}