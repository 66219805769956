.specialist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.availableSpecialist {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 2rem;


}




.specialistCards {
    width: -moz-fit-content;
    width: 8rem;
    height: -moz-fit-content;
    height: fit-content;
    border: 2px solid var(--border-color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
    font-size: 11px;
    gap: 9px;
    text-decoration: none;
    color: black;
}

.specilityIcons {
    width: 4rem;
    height: 4rem;
    /* background-color: #7471F5; */
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

}

.specilityIcons img {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.specilityIcons img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #514EF3;
}

.specialistCards span {
    color: #514EF3;
    font-size: 35px;


}