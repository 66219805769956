.diagnosticsProfileSection {
  flex: 1;
  border: 2px solid var(--dashboard-card-border);
  overflow: hidden;
  border-radius: 15px;
  padding: 10px;
  gap: 1rem;
}

.searchSection {
  width: 17rem;
  height: 2.5rem;
  position: relative;
}

.searchSection input {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 2px solid var(--dashboard-card-border);
  outline: none;
  padding: 10px;
}

.searchSection .searchSectionBtn {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  top: 25%;
  right: 15px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.dateFilterModalBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: fit-content;
  width: fit-content;
  gap: 5px;
  color: var(--dashboard-card-border);
  background-color: transparent;
  border: 1.5px solid var(--dashboard-card-border);
  outline: none;

  padding: 5px 10px;
  border-radius: 14px;
  cursor: pointer;
}

.modalCalender {
  width: fit-content;
  border: 2px solid var(--dashboard-card-border);
  padding: 25px 10px;
  border-radius: 15px;
}

.applyFilterBtn {
  width: fit-content;
  height: fit-content;
  padding: 5px 15px;
  border-radius: 14px;
  border: none;
  outline: none;
  background-color: var(--btn-color);
  color: white;
  cursor: pointer;
  /* font-size: 2rem; */
}

.diagnosticsPatientCard {
  width: 100%;
  height: fit-content;
  border: 2px solid var(--dashboard-card-border);
  border-radius: 15px;
  padding: 20px 14px;
}

/* .diagnosticsPatientCard * {
    justify-content: center;
} */

.viewdetailsDiagnostics {
  background-color: transparent;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
}

.diagnosticsBasicDetails {
  height: 100%;
}

.justify-center {
  justify-content: center;
}

.aligh-center {
  justify-content: center;
}

.diagosticsStatus {
  width: fit-content;
  border-radius: 5px;
  /* background-color: ; */
  padding: 0 7px;
  background-color: var(--diagnostics-status-back-color);
  color: var(--diagnostics-status-color);
}

.diagnosticsBasicDetails span {
  font-size: 13px;
  font-weight: bold;
  color: var(--dashboard-card-border);
}

.viewdetailsDiagnostics img {
  width: 1.3rem;
  height: 1.3rem;
}

.diagnosticsStatusPreviewer {
  /* color: #165e3d;
  background-color: #edffea; */
  border-radius: 5px;
  padding: 0 10px;
}

.diagnosticUploadSection button {
  width: fit-content;
  height: fit-content;
  padding: 7px 15px;
  border-radius: 15px;
  background-color: var(--btn-color);
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
}

.diagnosticUploadSection img {
  cursor: pointer;
}


/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropdown-toggle {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: none;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-menu {
  display: block;
}

/* Dropdown menu items */
.dropdown-item {
  padding: 8px 12px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  border: none;
  width: 100%;
  display: flex;
}

/* Dropdown menu item hover */
.dropdown-item:hover {
  background-color: #bebcbc;
}