.AppointmentSelection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.appointmentBookingFilter {
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    border: 2px solid var(--border-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    gap: 5px;
    text-decoration: none;
    color: black;
    cursor: pointer;
    position: relative;
}

.appointmentBookingFilter img {
    width: 5rem;
    height: 5rem;
}

.appointmentBookingFilter button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

  