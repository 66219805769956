.medicineSection {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.medicineAddBody {
  width: 100%;
  flex: 1;
}
#addNewMedicineSection{
  height: 50vh;
  overflow-y: scroll;
}
#addNewMedicineSection::-webkit-scrollbar{
  /* height: 53vh;
  overflow-y: scroll; */
  display: none;
}
.medicineData {
  width: 100%;
  /* height: 2.5rem; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-bottom: 2px solid var(--dashboard-card-border);
  padding-bottom: 6px;
  padding-top: 6px;
  gap: 0.5rem;
}
.medicineData td {
  color: gray !important;
  font-weight: 700;
}

.medicineData input{
width: 50%;
height: 2.5rem;
border: 2px solid var(--dashboard-card-border);
border-radius: 8px;
outline: none;
padding: 7px;
}

.changeAddBtn{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
 background-color: #00CC6F; 
 border: none;
 outline: none;
 border-radius: 6px;
 color: white;
 height: 100%;
 padding: 12px 12px;
 cursor: pointer;
 overflow: hidden;
}
.changeAddBtn span{
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: transparent;

}
.remove-button{
  position: relative;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
}
.remove-button span{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}