.PatientBasicForm {
    width: 100%;
    height: 85%;
    border: 2px solid var(--border-color);
    border-radius: 15px;
    overflow-y: scroll;
    padding: 15px;
}

.PatientBasicForm::-webkit-scrollbar {
    display: none;
}

.patientAddActionButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.patientAddActionButton button {
    width: 8rem;
    height: 2.5rem;
    cursor: pointer;
    background-color: var(--btn-color);
    color: white;
    border-radius: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.patientAddActionButton button img {
    width: 1rem;
    height: 1rem;
}

.patientAddActionButton button:disabled span {
    color: black !important;
}
.patientAddActionButton button:disabled img{
    filter: invert(1);
}

.patientAddActionButton button:disabled  {
    background-color: var(--border-color) !important;
    cursor: not-allowed;
}

.patientAddActionButton button span {
    color: white;
}

.flex-btn-sec{
    display: flex;
    align-items: center;
    justify-content: space-between;
}