.rbc-toolbar span:last-child,
.rbc-toolbar span:first-child {
    /* display: none !important; */
}

.rbc-time-content::-webkit-scrollbar {
    display: none;
}

.rbc-timeslot-group {
    min-height: 6rem !important;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
    position: relative !important;
    background-color: transparent;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;

}

.rbc-event {
    position: relative !important;
    width: fit-content !important;
}


.rbc-event::before {
    content: "" !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    background-color: var(--status-color) !important;
    width: 5px !important;
    height: 100% !important;
    z-index: 0;
}

.rbc-btn-group {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 0.5rem !important;
}

.rbc-btn-group button {
    width: 7rem;
    height: 2.5rem;
    border-radius: 8px !important;
    background-color: var(--btn-color) !important;
    cursor: pointer;
    color: white !important;
    cursor: pointer;
}

.rbc-btn-group button:hover {
    background-color: transparent !important;
    color: black !important;
    border: 2px solid var(--inactive-svg-fill);
}