* {
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
}

.patientProfile {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.5rem;


}

.patientProfileFetures {
    width: fit-content;
    flex: 1 1;
    display: grid;
    align-items: flex-start;
    grid-column-gap: 2rem;
    grid-template-columns: repeat(3,1fr);
    grid-row-gap: 1.5rem;
}
.PatientprofileLinkCards{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    /* padding-bottom: 2rem; */
}

.PatientprofileLinkCards::-webkit-scrollbar{
    display: none;
}
.patientcardsBtns button:last-child{
    cursor: pointer ;
}