.adminProfileSetting {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 1rem;
}
.adminProfileSetting::-webkit-scrollbar{
    opacity: 0;
}
.profilePreview {
    height: 6rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.profilePicAndIdName {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.profilePicAndIdName img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 2px solid var(--border-color);
}

.profilePicAndIdName div span {
    color: var(--inactive-svg-fill);
    font-weight: 700;
}

.profilePicAndIdName div h2 {
    font-weight: 700;
    position: relative;
    /* color: var( --inactive-svg-fill); */
}

.profilePicAndIdName div h2::after {
    content: attr(value);
    width: fit-content;
    height: fit-content;
    padding: 5px 10px;
    background-color: var(--btn-color);
    font-size: 10px;
    color: white;
    border-radius: 15px;
    position: absolute;
    text-wrap: nowrap;
    top: 1px;
    right: -94px;
    font-weight: lighter;
}

.logoutBtn {
    width: fit-content;
    height: fit-content;
}

.logoutBtn a{
    text-decoration: none;
    color: white;
    background-color: #FF3236;
    padding: 7px 20px;
    border-radius: 15px;
    border: none;
    outline: none;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3px;
    cursor: pointer;
}

.logoutBtn a span{
    color: white ;

}

.adminBasicDetails{
    border: 2px solid var(--inactive-svg-fill);
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 15px;
    padding: 7px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
}
.adminAddressDetails{
    border: 2px solid var(--inactive-svg-fill);
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 15px;
    padding: 7px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
}
.editbtn{
    width: fit-content;
    padding: 7px 17px;
    border-radius: 23px;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    border: 2px solid var(--inactive-svg-fill);
    outline: none;
    cursor: pointer;
}
.editbtn svg{
    width: 1rem;
    height: 1rem;
    /* cursor: pointer; */
}
.adminDetailsFormLabel{
    color: var(--inactive-svg-fill);
    font-weight: 700;
}