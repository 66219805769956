.PatientListAndDateFilter {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: flex-start;
    margin-bottom: 5px;
}

.doctorprofilePatientList {
    width: 50%;
    height: 100%;
    border: 2px solid var(--dashboard-card-border);
    margin-bottom: 0.5rem;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.patientListFilterByBtn {
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 1.5rem;

}

.patientListFilterByBtn input {
    appearance: none;
    position: relative;
    width: 7rem;
    height: 2.2rem;
    /* background: var(--btn-color); */
    border-radius: 22px;
    /* color: white; */
    cursor: pointer;
}

.patientListFilterByBtn input::after {
    content: attr(value);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    color: black;
    border: 1px solid var(--btn-color);
    border-radius: 22px;
}

.patientListFilterByBtn input:checked:after {
    background-color: var(--btn-color);
    color: white;
}

.docConSultedPatientList {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.consultAdmitPatientList {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.docConSultedPatientList tr,
.docConSultedPatientList thead {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.docConSultedPatientList tr {
    border-bottom: 1px solid var(--dashboard-card-border);
    padding-bottom: 5px;
    padding-top: 5px;
}

.docConSultedPatientList tr th {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    text-align: center;
    justify-content: center;
    width: calc(100%/4);
    font-size: 15px;
    font-weight: 700;
}

.docConSultedPatientList thead tr th img {
    height: 1rem;
    width: 1rem;
}

.docConSultedPatientList tbody tr td {
    width: calc(100%/4);
    text-align: center;
    font-size: 14px;
    font-weight: 700;

}

.docConSultedPatientList .patientConsultedListBody {
    overflow-y: scroll;
    width: 100%;
    overflow-x: hidden;
}

.docConSultedPatientList .patientConsultedListBody::-webkit-scrollbar {
    width: 7px;
    opacity: 0;
}


.docConSultedPatientList .patientConsultedListBody::-webkit-scrollbar-thumb {
    /* display: none; */
    background-color: var(--dashboard-card-border);
    border-radius: 5px;

}



.docConSultedPatientList tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.docConSultedPatientList tbody,
.docConSultedPatientList tbody tr {
    width: 100%;
}

.docConSultedPatientList tbody td:last-child button {
    /* width: calc(100%/4); */
    width: fit-content;
    padding: 8px 20px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    background-color: var(--btn-color);
}

.test {
    height: 100%;
    width: 100%;
    border: 2px solid green;
}