.react-calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 17rem;
}

.react-calendar__navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__prev-button {

    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: transparent;
    border: 2px solid var(--border-color);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
}

.react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr) !important;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.react-calendar__navigation__label {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    width: fit-content;
}

.react-calendar__month-view__weekdays {
    height: 2rem;
    display: flex;
    flex-direction: row;

    align-items: center;
}


.react-calendar__month-view__weekdays__weekday,
.react-calendar__month-view__weekdays__weekday--weekend {}




.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-size: 13px;
}



.react-calendar__tile,
.react-calendar__month-view__days__day {
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    flex: auto;
    border: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}




.react-calendar__month-view__days__day abbr {
    font-size: 15px;
    /* font-weight: 700; */
}

.react-calendar__tile--active {
    background-color: #9795F8 !important;
    color: white !important;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--border-color);
}

.react-calendar__tile--now {
    background-color: #EDEDED;
}




.react-calendar__month-view__weekdays__weekday--weekend {
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar__month-view__days__day--weekend abbr {
    color: red;
}