.basicDataPatientForm {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    padding-bottom: 1.5rem;
}





.basicform {
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.basicform label {
    color: var(--forms-label-color);
}

.basicDataLeft,
.basicDataRight {
    width: 50%;

}


.DataRows {
    width: 17rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
    align-items: flex-start;
}

.DataRows select,
.DataRows input {
    width: 100%;
    height: 2.5rem;
    padding: 5px;
    border-radius: 15px;
    outline: none;
    border: 2px solid var(--border-color);
    background-color: #80808017;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

input:disabled {
    cursor: not-allowed;
}