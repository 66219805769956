.navTopSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
    border-bottom: 2px solid var(--border-color);
}

.NavTop {
    height: 100%;
    width: 112px;
    border-right: 2px solid #E2E4E8;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pageStatus {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
}

.pageStatus h3 {
    color: var(--page-heading-color);
}

.NavItems {
    width: 100%;
    height: 100%;
    border-top: none;
    border: 2px solid #E2E4E8;
    border-top: none;
}




.NavLogo {
    width: 4rem;
    height: 4rem;
}

.navBody {
    display: flex;
    flex-direction: row;
    /* flex: 1; */
    /* width: 7rem; */
    width: 121px;
    height: 100%;
}





.items {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
}

.items .navLinkList {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    list-style-type: none;
}

.items .navLinkList .inactive {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    padding: 12px;
    background-color: var(--nav-inactive-back-color);
    /* background-color: red; */

    transition: 0.2s;
}

.items .navLinkList .active {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    padding: 12px;
    background-color: var(--nav-btn-back-color);

    transition: 0.2s;
}

.items .navLinkList .active svg {
    fill: white;
}

.items .navLinkList .active {

    box-shadow: 0 0 15px -7px;
}

.items .navLinkList .inactive {

    box-shadow: 0 0 15px -7px;
}

.items .navLinkList .inactive svg {
    fill: var(--inactive-svg-fill);
}

#logoutBtn {
    background-color: red !important;
    position: relative;
}


#logout{
    background-color: red;
}




.items .navLinkList a svg {
    transition: 0.5s ease;
}

.items .navLinkList a {
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


.items.navLinkList img {
    width: 100%;
    height: 100%;
    fill: white;
    filter: invert(1);
}

.active {
    background-color: var(--nav-btn-back-color);
    height: 3rem;
    width: 3rem;
    padding: 13px;
    border-radius: 50%;
}

.items ul li:hover {
    width: 3.5rem;
    height: 3.5rem;
    transition: 0.2s;
}

.pageDetails {
    /* flex: 1; */
    height: 90%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}
.emricon:active{
fill: white;
}