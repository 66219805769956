.sectionWrapper{
    border: 1px solid  var(--dashboard-card-border);
    width: 90%;
    height: 85%;
    border-radius: 15px;
    background-color: #eff8ff;
}

.sectionWrapper .heading{
    margin-top: 18px;
}

.sectionWrapper .heading p{
    padding-left: 29px;
    font-weight: bold;
    font-size: 20px;
}

.sectionWrapper .DownloadBtnParentWrapper{
    display: flex;
    height: inherit;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper{
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}


.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .btn button{
    border: 0px;
    outline: none;
    background-color: transparent;
    font-size: 16;
    font-weight: 700;
    cursor: pointer;
}


.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .btn{
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid  var(--dashboard-card-border);
    border-radius: 15px;
    cursor: pointer;
}

.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .consultation img{
    filter: invert(100%);
}

.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .consultation:hover{
    background-color: #d8e8fd94;
    transition: 0.5s;
}


.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .download{
    background-color: #514EF3;
    transition: 0.5s;
}

.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .download:hover{
    background-color: #7a78f7;
    transition: 0.5s;
}

.sectionWrapper .DownloadBtnParentWrapper .DownloadBtnWrapper .download button{
    color: #fff;
}