.addBillingHeader {
    background-color: white;
    width: 100%;
    height: fit-content;
    padding-bottom: 15px;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-bottom: none;
}

.billingAddBtn:disabled {
    background: gray !important;
    color: white;
    cursor: not-allowed;
}

.billingAddBtn {
    position: relative;
    background-color: #00CC6F;
}

.billingAddBtn span:last-child {
    /* position: relative; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}


.addBillingSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border-radius: 15px;
    padding: 10px;
    border: 2px solid #E4E6E8;
}

.billingAddList {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.addBillingSectionList {
    width: 40%;
}

.billingAddList table {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.billingAddList table tbody tr:last-child {
    border-bottom: 2px solid black !important;
}

.billingAddList table tr:last-child {
    border: none !important;
}

.billingAddList table tr {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
}

.addNewBillingInputBtn {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.billingAddList table th,
.billingAddList table td {
    width: 50%;
    text-align: center;
}

.billingAddList .addBillingSectionList tr {
    width: 100%;
    border-bottom: 2px solid #E4E6E8 !important;
}

.billingAddList table th:first-child,
.billingAddList table td:first-child {
    text-align: start !important;
}
































.addNewBillingInputBtn button {
    border-radius: 11px;
    background-color: var(--btn-color);
    color: white;
    cursor: pointer;
    padding: 8px 15px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.addNewBillingInputBtn button span,
.addAndPrintAddBillingBtnSection a:first-child span,
.addAndPrintAddBillingBtnSection .onPageActionBtn button:first-child span,
.addAndPrintAddBillingBtnSection .onPageActionBtn button:last-child span {
    color: white;
}

#changingInput {
    width: 100%;
    text-align: center;
    border: 2px solid var(--border-color);
    border-radius: 8px;
    padding: 8px;
}


.addAndPrintAddBillingBtnSection {
    width: 100%;
    height: 2.5rem;
    /* border: 2px solid red; */
}

.addNewBillingPart {
    width: 100%;
    height: 100%;
}

.addAndPrintAddBillingBtnSection a:first-child {
    border-radius: 11px;
    background-color: var(--btn-color);
    color: white;
    cursor: pointer;
    padding: 8px 15px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-decoration: none;
    font-size: 14px;
}


/* back print and other btns */
.addAndPrintAddBillingBtnSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.addAndPrintAddBillingBtnSection .onPageActionBtn button:first-child,
.addAndPrintAddBillingBtnSection .onPageActionBtn button:last-child {

    padding: 5px 10px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    background-color: var(--btn-color);
    color: white;
    border-radius: 12px;
    border: none;
    outline: none;
}

/* .addAndPrintAddBillingBtnSection .onPageActionBtn button:last-child {
    background-color: #00CC6F !important;
} */

.onPageActionBtn {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

}

.addAndPrintAddBillingBtnSection button {
    padding: 5px 10px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    background-color: var(--btn-color);
    color: white;
    border-radius: 12px;
    border: none;
    outline: none;
}

.addAndPrintAddBillingBtnSection span{
    color: #fff;
}