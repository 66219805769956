.payroleStructure{
   width: 100%; 
   height: 100%;
   border: 2px solid red;
   display: flex;
   flex-direction: row;
   /* align-items: center; */
}
.payroleStructure div:nth-child(1){
    width: 24rem;
    height: 100%;
    border: 2px solid red;
}
.payroleStructure div:nth-child(2){
    border: 2px solid red;
flex: 1;
}