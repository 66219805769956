.DoctorProfileCardOuter {
    width: 19rem;
    height: 20rem;
    border: 1px solid rgba(116, 124, 144, 0.50);
    border-radius: 15px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    gap: 1rem;
}

.DoctorProfileCardInner {
    width: 17.5rem;
    flex: 1;
    border: 1px solid rgba(116, 124, 144, 0.50);
    border-radius: 15px;
    background-color: #ECEFF4;
}


.DoctorProfileCardInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 15px;
}

.DoctorProfileCardInner .doctorSpecialty {
    width: fit-content;
    height: fit-content;
    padding: 8px 12px;
    background-color: white;
    border-radius: 15px;
}

.DoctorProfileCardInner .doctorBasicDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.DoctorProfileCardInner .doctorBasicDetails p {
    color: rgba(37, 37, 57, 0.50);
}

.DoctorProfileCardInner .doctorContactDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.DoctorProfileCardInner .doctorContactDetails div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: rgba(37, 37, 57, 0.50);
}

.DoctorProfileCardInner .doctorContactDetails div img {
    width: 1rem;
    height: 1rem;


}

.doctorSelectionBtn {
    width: 50%;
    height: 3rem;
    background-color: var(--btn-color);
    color: white;
    border-radius: 17px;
    border: none;
    outline: none;
    cursor: pointer;
}