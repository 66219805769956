.patientForm {
    margin-top: 1.5rem;
}

.riskfactorConfirmation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    width: 100%;
}

.checkInputButtons {
    position: relative;
    appearance: none;
    width: 6rem !important;
    height: 2rem !important;
    border: 1px solid var(--btn-color) !important;
    background-color: transparent;
    outline: none;
    border-radius: 22px !important;
    overflow: hidden;
    cursor: pointer;
}

.riskFactorInputsBtn {
    position: relative;
    width: fit-content !important;
    height: fit-content !important;
    overflow: hidden;
    border: 1px solid var(--btn-color) !important;
    background-color: transparent;
    outline: none;
    border-radius: 22px !important;
    cursor: pointer;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskFactorInputsBtn span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    padding: 5px 2rem 5px 5px !important;
    height: 100%;
}

.riskFactorInputsBtn input {
    border: none;
    appearance: none;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    left: 0;
    top: 0;
    cursor: pointer;
}

.riskFactorInputsBtn input::after {
    content: attr(value);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    font-size: 15px;
    opacity: 0;
}

.riskFactorInputsBtn input:checked::after {
    opacity: 1;
    background-color: var(--btn-color);
    color: white;
}

.checkInputButtons::after {
    content: attr(value);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.checkInputButtons:checked::after {
    border: none;
    background-color: var(--btn-color);
    color: white;
}
.riskFactorInputsBtn .tagsRemover {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;

    position: absolute;
    right: 10px;
    fill: gray;
    background-color: gray;

}

.basicAvilableRiskFactor {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
}

.ColRapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 9px;
    align-items: flex-start;

}


.addTags {
    width: fit-content;
    height: fit-content;
    border: 1.5px solid var(--btn-color);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    padding: 0 10px;
    cursor: pointer;
    outline: none;
    background-color: var(--border-color);

}



.addTags svg {
    border-radius: 50%;
    /* background-color: gray; */
    fill: white;
    height: 1rem;
    width: 1rem;
}

.addTags span {
    padding: 5px 0;
}

.tagcreateInput {
    width: 6rem;
    height: 2rem;
    border: 1px solid var(--btn-color);
    border-radius: 22px;
    overflow: hidden;
    position: relative;
}

.tagcreateInput img {
    position: absolute;
    right: 10px;
    top: 23%;
    background-color: gray;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    cursor: pointer;
}

#AddTagInputId {
    width: 100%;
    height: 100%;
    padding: 5px;
}

.notesInput {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.notesInput textarea {
    /* width: 8rem; */
    /* height: 4/rem; */
    border: 2px solid var(--border-color);
    outline: none;
    border-radius: 7px;
    padding: 5px;
}

.medicalproblemsNotes {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    gap: 3rem;

}

.addMoreBtn {
    padding: 10px 1rem;
    background-color: var(--btn-color);
    border-radius: 15px;
    gap: 0.5rem;
}

.addMoreBtn span,
.addTags span {
    color: white;
    font-size: 13px;
}

.addMoreBtn,
.addTags {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--btn-color);
}

.riskFactorrmation {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.formHeading {
    height: 2rem;
}

.AddNewInput {
    width: 8rem !important;
    height: 2.5rem !important;
    border-radius: 22px !important;
    outline: none !important;
    border: 2px solid var(--btn-color) !important;
    /* cursor: text !important; */
}

.AddNewInput input {
    padding: 5px 10px;
    padding-right: 30px;
    outline: none;
    border: none;
    cursor: text !important;
}