.medicalProblemsSection {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
    /* padding-bottom: 1.5rem; */
    border-right: 2px solid gray;
    padding-right: 1rem;
}


.formHeading {
    font-size: 18px;
    font-weight: bold;
}

.prevMedicalSelector {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.medicalProblemsForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    overflow-y: scroll;
}
.medicalProblemsForm::-webkit-scrollbar{
    display: none;
}

.prevMedicalSelector div {
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid var(--btn-color);
    position: relative;
    overflow: hidden;
    height: fit-content;
    border-radius: 22px;

}

.prevMedicalSelector div span {
    position: relative;
    /* z-index: 2; */
    text-wrap: nowrap;
    padding: 5px 20px 4px 0px;

}



.prevMedicalSelector div input {
    /* background-color: rebeccapurple; */
    position: absolute;
    height: 100%;
    width: 100%;
    appearance: none;
    left: 0;
    top: 0;
    cursor: pointer;
}

.prevMedicalSelector div input::after {
    content: attr(value);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
}

.prevMedicalSelector div input:checked::after {
    color: white;
    display: flex;
    background-color: var(--btn-color);
}

.addMoreBtn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;
    overflow: hidden;
    justify-content: center;
}
.prevMedicalSelector .addMoreBtn .addBtnSvg{
    fill: white;
}

.addMoreBtn span {
    padding: 0px !important;
}

.addMoreBtn img {
    background-color: white !important;
}



#extraInputCreate {
    padding: 5px;
    width: 6rem;
    height: 22px;
    border-radius: 22px;
    border: none;
    outline: none;
    position: relative;
}

.prevMedicalSelector div img {
    position: absolute;
    right: 4px;
    top: 22%;
    z-index: 12;
    background: gray;
    border-radius: 50%;
    cursor: pointer;

}

.addBtnSvg {
    position: relative !important;
}


.medicalproblemsNotes {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.medicalproblemsNotes .notesInput {
    width: 80%;

    /* border: 2px solid red; */
}

.medicalproblemsNotes .notesInput textarea {
    height: 6.5rem;
    width: 100%;
    border-radius: 15px;
    outline: none;
    border: 2px solid var(--dashboard-card-border);
    padding: 15px;
    background-color: linear-gradient(0deg, #EDF2F6 0%, #EDF2F6 100%), #FFF;
    resize: none;
}

.addExtranoteBtn .addMoreBtn {
    color: var(--btn-color);
    font-weight: bold;
}

