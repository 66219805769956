.SettingPageTitle {
    width: 55%;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 12px;
    position: relative;
}

.page * {
    font-family: 'Nunito', sans-serif;

}

.SettingPageTitle::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    width: 7rem;
    background-color: var(--btn-color);
}

.logoChangeSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
}

.LogoInputSec {
    height: 14rem;
    width: fit-content;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
}

.logoPreviewer {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    border: 2px solid var(--border-color);
    box-shadow: 0 0 20px -9px;
    position: relative;
    overflow: hidden;
    cursor: not-allowed;
    background: var(--PreviewImage);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.logoPreviewer::after {
    content: "Logo Preview";
    position: absolute;
    width: 100%;
    height: 20%;
    background-color: var(--btn-color);
    color: white;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoSelector {
    position: relative;
    height: 10rem;
    width: 10rem;
    border: 2px solid var(--border-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 52px;
    box-shadow: 0 0 20px -9px;
    overflow: hidden;
}

.logoSelector img {
    height: 50%;
    width: 50%;
}

.logoSelector::after {
    content: "Select Logo";
    position: absolute;
    width: 100%;
    height: 20%;
    background-color: var(--btn-color);
    color: white;
    left: 0;
    bottom: 0;
    text-align: center;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoSelector input {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
}

.hospitalUserData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}

.hospitalName,
.hospitalPassword {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}

.hospitalName input,
.hospitalPassword input {
    height: 2rem;
    width: 14rem;
    border-radius: 12px;
    border: 1.5px solid var(--placeholder-color);
    padding: 6px;
}

.settingControlButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
}

.settingControlButtons button {
    padding: 7px 2rem;
    border-radius: 15px;
    cursor: pointer;
    border: 2px solid var(--btn-color);
}

.settingControlButtons .settingSubmit {
    background-color: var(--btn-color);
    border: none;
    color: white;
    outline: none;
}