.prescreptionPage {
  height: fit-content;
  width: fit-content;
  margin: auto;
  align-items: center;
  padding: 1rem;
}

.prscriptionBody {
  /* transform: scale(0.43); */
  height: 100vh;
  border: 2px solid var(--dashboard-card-border);
}

.prescreptionPage * {
  color: black !important;
}


.medicineTableBody tr td {
  width: 50% !important;
  
  text-align: center !important;
}
.medicineTableBody tr  {
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
}

.prscriptionBody {
  width: 8.27in;
  height: 11.69in;
  /* background-color: red; */
  margin: auto;
  padding: 0 2rem;
  /* border: 2px solid yellow; */
}

.prescriptionHeader {
  height: 11rem;
  /* border: 2px solid green; */
  border-bottom: 5px solid black;
}

.prescriptionTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.prescriptionTable thead,
.prescriptionTable tbody,
.prescriptionTable tr {
  width: 100%;
}

.prescriptionTable thead tr th {
  width: 50%;
  font-weight: bold !important;
  text-align: center;
}

.prescriptionTable tbody tr td,th {
  width: 50%;
  text-align: center;
}

.prescriptionTable tbody,
thead tr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.prescriptionTable tbody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.prescreptionBtns button {
  padding: 10px 25px;
  border: none;
  background-color: var(--btn-color);
  color: white !important;
  border-radius: 13px;
  cursor: pointer;
}

.logo {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
}

.logo img {
  width: 100%;
  height: 100%;
}