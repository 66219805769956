.patientListActionBtn {
    width: fit-content;
    height: fit-content;
    padding: 7px 15px;
    text-decoration: none;
    color: white;
    background-color: var(--btn-color);
    border-radius: 22px;
    font-weight: normal;
}

.patientListActionBtn:first-child {
    background-color: white !important;
    border: 1px solid var(--btn-color) !important;
    color: black !important;
}