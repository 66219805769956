.appointBooingFormHeader {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.appointBooingFormSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.appointmentBookingForm {
    display: flex;
    flex-direction: column;

    gap: 2rem;

}

.appointmentBookingFormPatientType {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.appointmentBookingFormPatientType div {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
}

.appointmentBookingFormPatientType input {
    appearance: none;
    width: 8rem;
    height: 2.5rem;
    border: 2px solid #9795F8;
    border-radius: 22px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.appointmentBookingFormPatientType input::after {
    content: attr(value);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;

}

.appointmentBookingFormPatientType input:checked::after {
    background-color: #9795F8;
    color: white;
}

.appointmentBookingFormPatientType input:checked {
    border: none;
}

.appointBookingMentDateAndTime {
    width: 37rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;
}

.appointmentBookingDate {
    width: 37rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}



.appointmentBookingDate p {
    color: var(--inactive-svg-fill);
}

.appointmentBookingTime {
    width: 15rem;
    position: relative;
    margin-top: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3rem;

}

.appointmentBookingTimeInput {
    appearance: none;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 2.5rem;
    border-radius: 9px;
    border: 2px solid var(--inactive-svg-fill);
    overflow: hidden;

}

.appointmentBookingTimeInput::after {
    content: attr(value);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}

.appointmentBookingTimeInput:checked::after {
    background-color: #9795F8;
    color: white;
}

.appointmentBookingTimeInput:checked {
    border: none;
}

.appointmentBookingTimeInputSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.appointmentBookingRequestBtn {
    width: 100%;
    height: 2.5rem;
    border-radius: 8px;
    border: none;
    outline: none;
    background-color: #7b78f8;
    color: white;
    cursor: pointer;
}