.patientLogin {
    width: 100%;
    height: 85%;
    border: 2px solid var(--border-color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.patientLoginForm {
    width: 24rem;
    height: fit-content;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.patientLogin .formHeading {
    color: var(--btn-color);
    font-size: 23px;
    font-family: "Nunito", sans-serif;
}

.congratulationForm {
    width: 100%;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}

.patientLogin .patientLoginUserInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    outline: none;
    border: none;
}

.patientLogin .patientLoginUserInput input {
    width: 100%;
    height: 2.5rem;
    border: none;
    outline: none;
    border-radius: 7px;
    padding: 15px;
    background-color: #E0E4EC;
    font-family: "Nunito", sans-serif;
}

.patientLogin .patientLoginUserInput label {
    font-size: 14px;
    font-family: "Nunito", sans-serif;
}

.PatientUIDPatientLogin input {
    text-align: center !important;

}

.patientLoginBtn{
    width: 7rem;
    height: 2.5rem;
    border-radius: 8px;
    border: none;
    outline: none;
    background: var(--btn-color);
    color: white;
    cursor: pointer;
}

.formHeading {
    font-size: 23px;
    font-family: "Nunito", sans-serif;
}

.otherOptionPreviewer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.otherOptionPreviewer span:first-child,
.otherOptionPreviewer span:last-child {
    width: 33%;
    height: 3px;
    border-radius: 10px;
    background: var(--border-color);
}

.otherOptionPreviewer span:nth-child(2) {
    font-size: 21px;
    color: #49536E;
}