.diagnosisBody {
    height: 100%;
    width: 100%;
    padding-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2rem;
}


.addMoreDiagnosis {
    width: 100%;
    height: 100%;
}


.diagnosisContents {
    /* border-right: 2px solid var(--dashboard-card-border); */
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.diagnosisTextBoxes {
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.diagnosisTextBoxes textarea {
    width: 100%;
    height: 100%;
    border: 2px solid var(--dashboard-card-border);
    outline: none;
    border-radius: 15px;
    padding: 10px;
    resize: none;
}