.patientPageOverviewHeader {
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6rem;
}

.patientPageOverviewHeader * {
  font-family: "Nunito", sans-serif;
}

.patientPageAddiButtons {
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
  /* justify-content: space-between; */
}

.patientSearchSec {
  display: flex;
  align-items: center;
  width: calc(100% / 5);
  height: 3rem;
  position: relative;
}

.patientSearchSec input {
  width: 100%;
  border-radius: 17px;
  padding: 10px;
  padding-right: 3rem;
  height: 100%;
  font-size: 17px;
  background-color: #efeeee;
  border: 2px solid #efeeee;
  outline: none;
}

.patientSearchSec .searchButton {
  position: absolute;
  right: 20px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

.patientListName {
  width: 8rem;
  height: 2.5rem;
  border-radius: 21px;
  outline: none;
  border: none;
  background-color: var(--heading-color);
  color: white;
  cursor: text;
}

.filterButtons {
  width: 7rem;
  height: 2.5rem;
  border-radius: 21px;
  border: 1.5px solid var(--btn-color);
  color: white;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.filterButtons input {
  appearance: none;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  cursor: pointer;
}

.filterButtons input::after {
  content: attr(value);
  position: absolute;
  width: 100%;
  height: 100%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterButtons input:checked::after {
  background-color: var(--btn-color);
  color: white;
}

.ExportBtn {
  width: 8rem;
  height: 2.5rem;
  border-radius: 22px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  background-color: var(--btn-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  text-decoration: none;

  font-family: "Nunito", sans-serif;
  font-weight: bold;
}

.ExportBtn span {
  font-size: 13px;
  color: white;
}

.ExportBtn img {
  height: 19px;
  width: 19px;
}

.ResetBtn {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.patientList {
  width: 100%;
  /* height: fl; */
  flex: 1;
  height: fit-content;

  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  overflow-y: scroll;
  /* padding-bottom: 8px; */
}

.patientList::-webkit-scrollbar {
  display: none;
}

.showMoreContainer {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.showMoreContainer .showMoreBtn {
  cursor: pointer;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px 2rem;
  border-radius: 21px;
  border: 2px solid var(--btn-color);
  background: transparent;
  color: black;
  font-weight: bold;
  outline: none;
  transition:0.6s;
}

.showMoreContainer .showMoreBtn:hover{
  background-color: var(--btn-color);
  color: white;
  transition:0.6s;
}