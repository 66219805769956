.appointmentSelectionDocDepartment {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    overflow: hidden;
}

.appintmentSelectionheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.appointmentSelectionHeaderLeft {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.appointmentSelectionSearch {
    width: 24rem;

    height: 3rem;
    position: relative;
    /* border: 2px solid red; */
}

.appointmentSelectionSearch input {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-right: 37px;
    border-radius: 15px;
    border: 2px solid var(--border-color);
    outline: none;
    background-color: var(--border-color);
}

.appointmentSelectionSearch button {
    position: absolute;
    top: 33%;
    right: 10px;
    border-radius: 50%;

    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
}

.appointSelectionDoctors {
    flex: 1;
    /* border: 2px solid rebeccapurple; */
    width: 100%;
    overflow-y: scroll;
    padding-bottom: 5px;
}
.appointSelectionDoctors::-webkit-scrollbar{
    display: none;
}
.appointmentSelectionDoctorCards {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 3rem;
}