.billingSection {
    display: flex;
    flex-direction: column;
}

.billingSection {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.billigSectionTopFIlter {
    background-color: white;
    padding: 10px;
    width: 100%;
    border: 2px solid #E4E6E8;
    height: fit-content;
    padding-bottom: 15px;
    border-radius: 15px 15px 0 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.billingPageTitleAddBilling {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.billingPageTitle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.billingPageTitleAddBilling a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 15px;
    border-radius: 15px;
    border: none;
    outline: none;
    background-color: var(--btn-color);
    color: white;
    gap: 5px;
    cursor: pointer;
}

.billingPagePlusBtn {
    fill: white;
}

.billingPageTitleAddBilling a span {
    color: white;
}

.billingShortOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
}

.billingShortOption .flexRow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.billingShortOption .flexRow label {
    color: #4B5563;
}

.billingShortOption .flexRow input[type="date"] {
    width: fit-content;
    padding: 10px 15px;
    height: 2rem;
    border: 2px solid #E4E6E8;
    outline: none;
    color: black;
    border-radius: 11px;
}

.billingShortOption .flexRow input[type="date"]::-webkit-calendar-picker-indicator {
    /* color: red; */
    display: none;
}

.billingShortOption .flexRow input[type="checkbox"] {
    cursor: none;
}

.patientBillingList {
    flex: 1;
    display: flex;
    flex-direction: column;
}



.patientBillingList thead {
    display: flex;
    width: 100%;
    padding: 0 15px;
    align-items: center;
    justify-content: flex-start;
}

.patientBillingList thead tr {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    border-bottom: none;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #374151;
}

.patientBillingList tbody {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #E4E6E8;
    border-radius: 0 0 15px 15px;
    /* border-top: none; */
    padding: 0 15px;

}

.patientBillingList tbody .tableBody {
    width: 100%;
}

.patientBillingList tr {
    border-bottom: 2px solid #E4E6E8;
}

.patientBillingList tbody tr {
    padding: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #4B5563;
}

.billingListPrintBtn {
    height: 3rem;
}

.patientBillingList tr td,
.patientBillingList tr th {
    width: 100%;
}

.patientBillingList tr td:first-child,
.patientBillingList tr th:first-child {
    text-align: start !important;

}

.patientBillingList tr td,
.patientBillingList tr th {
    text-align: center;
}

.billingStatusViewer {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
    background: var(--billingStatusBackColorVari);
    padding: 6px;
}

.billingStatusViewer img,
.billingStatusViewer span {
    /* z-index: 5; */
    color: var(--billingStatusColorVari);
}




.billingListPrintBtn {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

.billingListPrintBtn button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 10px 15px;
    border-radius: 15px;
    background-color: var(--btn-color);
    color: white;
    font-weight: bold;
    border: none;
    outline: none;
    cursor: pointer;
}

.billingListPrintBtn button span {
    color: white;
}

.billingSection .sc-hHftZz {
    padding-bottom: 4.5rem;
}

.loadMore {
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
}

.loadMore button {
    cursor: pointer;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 1rem;
    border-radius: 21px;
    border: 2px solid var(--btn-color);
    background: transparent;
    color: black;
    font-weight: bold;
    outline: none;
    transition: 0.6s;
}

.loadMore button:hover {
    background-color: var(--btn-color);
    color: white;
    transition: 0.6s;
}