.dashboard {
  width: 80%;
  /* border: 2px solid red; */
  height: 100vh;
}

.NavCompiler {
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
}

.DashboardHeading {
  width: 100%;
}

.headerIcon {
  display: flex;
  flex-direction: row;
  height: 4rem;
  width: fit-content;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-family: "Nunito", sans-serif;
}
.headerIcon img {
  border-radius: 50%;
}

.DashboardHospitalName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--logo-color);
}

.DashboardHospitalName h2 {
  font-weight: 900;
}

.DashboardHeaderLogo {
  width: 4rem;
  height: 4rem;
}

/* sevice heading */

.DashboardServiceSec {
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.DashboardServiceSec h3 {
  font-size: 24px;
  color: var(--page-heading-color);
}

.DashboardServiceSec span {
  width: 200px;
  height: 3px;
  background-color: var(--page-heading-color);
  border-radius: 20px;
  margin-top: 3px;
}

/* Dashboard click able cards */
.dashboardScrolller {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.dashboardScrolller::-webkit-scrollbar {
  display: none;
}

.DashboardCards {
  height: fit-content;
  width: fit-content;
  display: grid;
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem;
  grid-template-columns: repeat(3, 1fr);
}

.DashboardCard {
  width: 267px;
  height: 196px;
  border: 2px solid var(--dashboard-card-border);
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--dashboard-card-color);
}

.cardInnerContentSec {
  width: 246px;
  height: 175px;
  border: 1px solid var(--dashboard-card-inner-border);
  background-color: var(--dashboard-card-back-color);
  border-radius: 17px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.dashboardCardSvgIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  width: 3rem;
  height: 3rem;
}

.cardLeft img {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  position: relative;
}

.DashboardCard:nth-child(1) .cardLeft img {
  width: 2rem;
  height: 2rem;
}

.cardLeft {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cardLeft h3 {
  font-size: 20px;
  font-family: "Nunito", sans-serif;
}

.PageName {
  width: 100%;
  font-size: 12px;
}

.dashboardSideImage {
  width: 30%;
  height: 30%;
  position: fixed;
  bottom: 0;
  right: 0;
  /* border: 2px solid red; */
}

.dashboardCardSvgBackground {
  width: 4rem;
  height: 4rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dashboardSideImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
