.page {
  display: flex;
  flex-direction: column;
}

.patientDiagnosticsList {
  /* width: 100%; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 3rem;
}

.patientDiagnosticsCards {
  width: 18rem;
  height: 4rem;
  border-radius: 9px;
  border: 2px solid var(--border-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 10px;
}

.patientDiagnosticsCards span {
  font-weight: bold;
}

.diagnosticItemIcon {
  width: 4rem;
  height: 3rem;
  position: relative;
}

.patientDiagnosticsCards .diagnosticItemIcon img {
  width: 4rem !important;
  height: 3rem !important;
  /* position: relative; */
}

.diagnosticItemIcon span {
  position: absolute;
  background-color: rgba(253, 155, 156, 0.2);
  border-radius: 11px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.patientDiagnosticsCards img:last-child {
  width: 1.8rem;
  cursor: pointer;
}

.selectedDiagnostic {
  background-color: rgba(211, 229, 254, 0.5);
}

/* Modal section */

.ReactModal__Content,
.ReactModal__Content--after-open {
  padding-top: 4rem !important;
  width: 38rem !important;
  height: 35rem !important;
  top: 12% !important;
  left: 29% !important;
  border-radius: 15px !important;
  /* background-color: #E2E4E8 !important; */
  box-shadow: inset 0px 0px 28px -12px white;
  display: flex !important;
  flex-direction: column !important;
  align-items: center;
  gap: 1rem !important;
  background: white !important;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  box-shadow: inset 0 0 45px -31px !important;
}

/* Modal section end*/

.modalSearch {
  width: 80%;
  /* border: 2px solid red; */
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.modalSearch input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  outline: none;
  border: 2px solid var(--border-color);
  border-right: none;
  padding: 11px;
  padding-right: 11%;
}

.modalSearch img {
  position: absolute;
  top: 30%;
  right: 4%;
}

.subDdiagnosticField {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: 1rem;
}

.subItemsCard {
  width: 14rem;
  height: 2.5rem;
  border: 2px solid #cacdd5;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 11px;
  position: relative;
}

.subItemsCard span {
  font-weight: 700;
}

/* .subItemsCard input[type=checkbox] {



    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
    cursor: pointer;
    position: relative;
border: 2px solid var(--btn-color);
border-radius: 8px;

}

.subItemsCard input[type=checkbox]::after {
    content: "\2713";
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    right: -10px;
    font-size: 1.5rem;
    color: var(--btn-color);
} */

.subDiagnosticTypeAdder {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 13.5rem;
  height: 2.5rem;
}

.subDiagnosticTypeAdder input {
  height: 100%;
  outline: none;
  width: 58%;
  border: 2px solid var(--dashboard-card-border);
  border-radius: 14px;
  padding: 10px;
}

.subDiagnosticTypeAdder button img {
  fill: var(--btn-color);
  color: var(--btn-color);
}

.subDiagnosticTypeAdder button {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  border: none;
  outline: none;
  gap: 5px;
  color: var(--btn-color);
  font-weight: bold;
  cursor: pointer;
}

.subDiagnosticTypeAdder button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.addmoreDiagnosticImg {
  fill: var(--btn-color);
}

/* sub diagnostics add successfully */

.addedSubdiagnosticBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.addedSubdiagnosticBtn button {
  width: fit-content;
  padding: 10px;
  background-color: var(--btn-color);
  color: white;
  outline: none;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.diagnosticScheduleNextBtnSection {
  width: 100%;
  /* position: absolute; */
  bottom: 0;
  height: 3rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.diagnosticAppointment {
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
  border-radius: 15px;
  border: none;
  background-color: var(--btn-color);
  color: white;
  text-decoration: none;
}

.extraDiagnosticItemAddedCards {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.extraDiagnosticItemAddedCards::-webkit-scrollbar {
  display: none;
}

.subDiagnosticAdderModalCloser {
  position: absolute;
  top: 9px;
  right: 23px;
  background-color: transparent;
  font-size: 2rem;
  color: var(--dashboard-card-border);
  outline: none;
  border: none;
  cursor: pointer;
}

