* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.loginPage {
    width: 100%;
    height: 100vh;
    /* overflow: hidden; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.loginPage * {
    font-family: 'Nunito', sans-serif;

}

.loginImg {
    width: 50%;
    height: 100%;
    overflow: hidden;
}

.loginImg img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.loginContent {
    flex: 1;
    height: 100%;
    padding: 15px;
    background-color: var(--nav-inactive-back-color);
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: center;
    justify-content: center;
}


.loginHeading {
    font-size: 43px;
    color: var(--heading-color);
    word-wrap: break-word;
    width: 80%;
    margin: auto;
    ;
}


.loginForm {
    width: 80%;
    margin: auto;
    height: fit-content;
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
}

.formInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.formInputs select option:nth-child(1) {
    color: var(--placeholder-color);
}

.formInputs select {
    /* color: var(--placeholder-color); */
    cursor: pointer;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 2px 4px 40px 0px rgba(0, 0, 0, .15);
}

.LoginInputElm {
    width: 100%;
    height: 3.5rem;
    padding: 10px;
    border: 2px solid var(--border-color);
    outline: none;
    border-radius: 11px;
    font-size: 16px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 2px 4px 40px 0px rgba(0, 0, 0, .15);
}

.LoginInputElm::placeholder {
    color: var(--placeholder-color);
}

.loginBtn {
    width: 100%;
    height: 3.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 13px;
    background: var(--heading-color);
    color: white;
    justify-content: center;
    gap: 4rem;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 2px 4px 40px 0px rgba(0, 0, 0, .20);
}

.loginBtn p {
    font-size: 15px;
    color: white;
    position: relative;
    z-index: 1;
    transition: opacity 0.3s ease;
}

.loginBtn:hover p {
    opacity: 0;
}

.loginBtn img {
    height: 1.4rem;
    width: 1.4rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.loginBtn:hover img {
    opacity: 1;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}