.allAccountCreatedList {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* border: 2px solid red; */
    padding: 10px;
    gap: 5px;
}

.allAccountCreatedList #selectRole {
    background-color: var(--btn-color);
    color: white;
}

.allAccountCreatedList #selectRole option {
    background-color: white;
    color: var(--btn-color);
}

.profileListPreviewText {
    width: fit-content;
    height: 2.5rem;
}

.adminPageAccountsList {
    width: 100%;
    height: 100%;
}

.adminPageAccountsList table {
    border: 1px solid #71717A;
    border-radius: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.adminPageAccountsList table thead tr,
.adminPageAccountsList table tbody tr {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #71717A;
    padding: 7px 0;
}



.adminPageAccountsList table thead tr th {
    font-size: 13px;
    color: #71717A;
    width: calc(100%/4);
}

.adminPageAccountsList table tbody tr td {
    font-size: 13px;
    color: #71717A;
    text-align: center;
    width: calc(100%/5);
}


.adminPageAccountsList table tbody::-webkit-scrollbar{
display: none;
}

.adminPageAccountsList table tbody tr td:last-child img {
    cursor: pointer;
}

.pageChangerSection {
    width: 10rem;
}

.pageCounts {
    height: 1.5rem;
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    flex-direction: row;
}
.dataTableActionBtn{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.rdt_TableBody{
    height: 58vh;
    overflow-y: scroll;
}
.rdt_TableBody::-webkit-scrollbar{
    /* display: ; */
    opacity: 0;
}

.loaderSpinner {
    height: 50px;
    width: 50px;
    border: 4px solid transparent;
    border-bottom: 4px solid #514ef3;
    border-left: 4px solid #514ef3;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
    margin-bottom: 20px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}