.patientForm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    overflow-y: scroll;
}
.patientForm::-webkit-scrollbar{
    display: none;
}


.kinsDetailsConfirmationBtn {
    appearance: none;
    width: 6rem;
    height: 2rem;
    border: 1px solid var(--btn-color);
    border-radius: 22px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}


.kinsDetailsConfirmationBtn::after {
    content: attr(value);
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.kinsDetailsConfirmationBtn:checked::after {
    color: white;
    background-color: var(--btn-color);
    border: none;
}

.familyDetailConfirmation {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.familyHistorySection {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
}

.familyInformation {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.prevMedicalSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.addBtnSvg {
    fill: white;
}

#extraInputCreate {
    width: 6rem;
    height: 2rem;
    border-radius: 22px;
    padding: 5px;
    border: 1px solid var(--dashboard-card-border);
    outline: none;
}
.addMoreBtn{
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    border-radius: 22px;
}

.addMoreBtn span:last-child {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

