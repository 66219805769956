.settingNavbar {
    width: 100%;
    height: 2rem;
    /* height: fit-content; */
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 0.5rem;

}

.settingNavbar ul {
    /* text-decoration: none; */
    list-style-type: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
}

.settingNavbar ul li {
    font-size: 16px;
    font-weight: 700;

}

.topInActiveNavbar,
.topActiveNavbar {
    text-decoration: none !important;
    position: relative;
}

.topActiveNavbar::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -9px;
    width: 100%;
    height: 3px;
    background-color: var(--btn-color);
}