.allAppointmentsSection {
  /* border: 2px solid red; */
  flex: 1;
  overflow: hidden;
  /* border: 2px solid red; */

}


.appointmentListSearchInput {
  width: 20rem;
  position: relative;
}

.appointmentListSearchInput input {
  width: 100%;
  height: 100%;
  border-radius: 22px;
  border: 2px solid var(--dashboard-card-border);
  outline: none;
  padding: 10px;
  padding-right: 3rem;
}

.appointmentListSearchInput svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 19%;
  right: 16px;
  cursor: pointer;
}