.overViewCards {
    width: calc(100%/3);
    height: 8rem;

    border-radius: 13px;
    /* border: 2px solid red; */
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 22px -13px #344347;

}

.patientPageOverviewHeader {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    /* flex-wrap: wrap; */
    padding-right: 5px;
}

.overviewUpperCardSec {
    height: 50%;
    /* border: 2px solid green; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
}

.overviewUpperCardSec span:nth-child(1) {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    overflow: hidden;
    padding: 10px;
    background-color: var(--patient-overview-card-svg-back);
    display: flex;
    align-items: center;
    justify-content: center;
}
.overviewUpperCardSec span:nth-child(1) img:nth-child(1){
width: 100%;
height: 100%;
}

.overviewUpperCardSec h3 {
    width: fit-content;
    flex: 1 1;
    font-size: 23px;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
}

.overviewCardBottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    height: 50%;
}

.overviewCardBottom h2:nth-child(1) {
    font-size: 35px;
    font-family: "Nunito", sans-serif;
}

.anlysisGraphIndicatorSec {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: fit-content;
    gap: 7px;

}

.anlysisGraphIndicatorSec .indicatorData {
    color: var(--overViewCardIndicatorColor);
}

.anlysisGraphIndicatorSec span:last-child {
    /* color: var(--overViewCardIndicatorColor); */
    color: gray;
    font-size: 11px;

    font-family: "Nunito", sans-serif;
}