.patientCards {
    /* border: 2px solid red; */
    height: fit-content;
    border-radius: 15px;
    padding: 12px 13px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    border: 1px solid rgba(116, 124, 144, 0.50);
    width: 100%;
}

.patientCards * {

    font-family: 'Nunito', sans-serif;
}

.patientcardsLeft {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: fit-content;
    gap: 10px;
}

.patientCardUpperTop {
    height: fit-content;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 1.5rem;
}


.patientCardUpperTop h3 {
    font-size: 24px;
}

.patientCardUpperTop p {
    font-size: 15px;
}

.patientContactDetails {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
}


.patientContactCards {
    width: fit-content;
    /* flex: 1; */
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    /* border: 2px solid red; */

}

.patientContactSvg {
    height: 3rem;
    width: 3rem;
    background-color: white;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px -9px;
    border-radius: 50%;

}

.contactContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.contactContent h3 {
    font-size: 17px;
}

.contactContent p {
    font-size: 13px;
    font-weight: lighter;
    color: gray;
}

.patientcardsBtns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 1.5rem;
}

.patientcardsBtns button {
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    padding: 12px 2rem;
    border-radius: 21px;
    border: 2px solid var(--btn-color);
    background: transparent;
    color: black;
    outline: none;
}

.patientcardsBtns button:nth-child(1) {
    cursor: text;
    text-wrap: nowrap;
}


.patientcardsBtns button:last-child {
    background-color: var(--btn-color);
    color: white;
}


/* appointment */

.appintmentList {
    width: 100%;
    height: fit-content;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 26px;
    align-items: flex-start;
    justify-content: flex-start;
}

.appintmentList::-webkit-scrollbar {
    display: none;
}