.ParentModalWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 20px 0;
}

.ParentModalWrapper .heading{
        display: flex;
        gap: 1rem;
}