.allEmr {
    flex: 1;
    /* border: 2px solid red; */
    overflow: hidden;
}

.pageTitle {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.pageTitle img {
    height: 20px;
    padding-right: 10px;
    cursor: pointer;
}


.allEmrProfileSection {
    width: 20rem;
    height: 100%;
    /* border: 2px solid green; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 2px solid var(--dashboard-card-border);
    border-radius: 23px;
    overflow: hidden;
}

#dateShorter {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

#dateShorter option {
    background-color: transparent !important;
}

#dateShorter option:hover {
    background-color: green !important;
}

.searchPatientEmrInput {
    width: 100%;
    height: 2.5rem;
    position: relative;
}

.searchPatientEmrInput input {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    outline: none;
    border: 1.5px solid var(--dashboard-card-border);
    padding: 10px;
    padding-right: 2.5rem;
}

.searchPatientEmrInput input::placeholder {
    font-weight: 700;
    letter-spacing: 1px;
}

.searchPatientEmrInput img {
    position: absolute;
    top: 26%;
    right: 10px;
    cursor: pointer;
}

.patientProfileSubCard {
    height: fit-content;
    width: 100%;
    /* border: 2px solid greenyellow; */
    padding: 11px 6px;
    border-bottom: 1.5px solid var(--dashboard-card-border);
    /* box-shadow: inset 0 0 26px -20px; */

}

.patientSubprofileSelectorImg {
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(0, 0, 255, 0.25);
    padding: 3px;
    border-radius: 50%;
}

.patientSubprofileSelectorImg span {
    border-radius: 50%;
    background-color: rgba(0, 0, 255, 0.25);
    width: 100%;
    height: 100%;
    color: black;


}

.emrSelector {
    appearance: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    cursor: pointer;
}

.patientProfileSubCard:has(input[type="radio"]:checked) {
    background-color: #ECECFF;

}

.patientProfileSubCard div {
    position: relative;
}